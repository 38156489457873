import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import constants from "@/common/constants";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (!store.getters["user/token"]) {
    next();
    return;
  }
  next("/");
};

const routes = [
  {
    path: "",
    component: () => import("@/layouts/MainLayout"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/",
        name: "PageHome",
        meta: {},
        component: () => import("@/views/page/PageHome"),
      },
      {
        path: "/users",
        name: "UserList",
        meta: { auth: true, roles: ["admin"] },
        component: () => import("@/views/UserList"),
      },
      {
        path: "/logs",
        name: "LogList",
        meta: { auth: true, roles: ["admin"] },
        component: () => import("@/views/LogList"),
      },
      {
        path: "/statistics",
        name: "StatisticList",
        meta: { auth: true, roles: ["partner"] },
        component: () => import("@/views/StatisticList"),
      },
      {
        path: "/profile",
        name: "PersonalData",
        meta: { auth: true, roles: ["partner"] },
        component: () => import("@/views/PersonalData"),
      },
    ],
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/views/page/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    const user = store.getters["user/user"];
    if (user) {
      if (constants.roles.includes(user.role)) {
        if (
          "roles" in to.meta &&
          to.meta.roles &&
          to.meta.roles.includes(user.role)
        ) {
          next();
        } else {
          next();
        }
      } else {
        store.dispatch("user/logoutUser", {}, { root: true });
        next({ name: "Login" });
      }
    } else {
      next({ name: "Login" });
    }
  } else {
    next();
  }
});

export default router;
