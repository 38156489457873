import restApi from "@/plugins/http";

export class Api {
  execute(method, resource, data, config = null) {
    return new Promise((resolve, reject) => {
      restApi[method](`/api/v1/${resource}`, data, config)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  login(data) {
    return this.execute("post", "auth", data);
  }
  resetPassword(data) {
    return this.execute("post", "reset-password", data);
  }

  /* User */
  createUser(data) {
    return this.execute("post", "user", data);
  }
  getUsers(data) {
    return this.execute("get", "user", data);
  }
  getUser(id, data) {
    return this.execute("get", `user/${id}`, data);
  }
  updateUser(id, data) {
    return this.execute("put", `user/${id}`, data);
  }
  deleteUser(id) {
    return this.execute("delete", `user/${id}`);
  }

  fileUploader(data) {
    return this.execute("post", "file-uploader", data);
  }

  getStatistics(data) {
    return this.execute("get", "statistics", data);
  }
  getStatisticsExcel(data) {
    return this.execute("get", "statistics/excel", data);
  }

  getLogs(data) {
    return this.execute("get", "log", data);
  }
  createLog(data) {
    return this.execute("post", "log", data);
  }

  getQRCode(data) {
    return this.execute("get", "qr-code", data);
  }

  getAdata(data) {
    return this.execute("get", "adata", data);
  }
}
