export default {
  roles: ["admin", "partner", "super_partner"],
  MESSAGE: {
    SUCCESS: "Успешно.",
    ERROR: {
      NOT_FOUND:
        "Элемент не найден.<br />Обратитесь к администратору за дополнительными сведениями.",
      SERVER:
        "Произошла ошибка.<br />Обратитесь к администратору за дополнительными сведениями.",
      FORM: "Заполните все необходимые поля.",
      LOGIN: "Неправильный логин или пароль.",
    },
  },
};
