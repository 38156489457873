import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/moment";
import constants from "@/common/constants";
import { Api } from "@/common/api";

Vue.config.productionTip = false;
Vue.prototype.$constants = constants;
Vue.prototype.$api = new Api();
Vue.prototype.$mediaUrl = process.env.VUE_APP_API_URL + "media/";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
