<template>
  <v-app>
    <loading
      :active.sync="loading"
      :can-cancel="parameters.loading.canCancel"
      :color="parameters.loading.color"
      :loader="parameters.loading.loader"
      :width="parameters.loading.width"
      :height="parameters.loading.height"
      :background-color="parameters.loading.backgroundColor"
      :opacity="parameters.loading.opacity"
      :z-index="parameters.loading.zIndex"
    />
    <v-snackbar
      v-model="notificationStatus"
      :timeout="parameters.notification.timeout"
      top
      app
      right
    >
      <span v-html="notificationText"></span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="error"
          text
          v-bind="attrs"
          @click="notificationStatus = false"
        >
          закрыть
        </v-btn>
      </template>
    </v-snackbar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "@/assets/styles/app.scss";
@import "@/assets/styles/mobile.scss";
</style>

<style>
@import "@/assets/styles/materialdesignicons.min.css";
</style>

<script>
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

Vue.mixin({
  data: () => ({
    selectGlobal: {
      roles: {
        admin: { text: "Админ", value: "admin" },
        partner: { text: "Партнер", value: "partner" },
        super_partner: { text: "Супер-партнер", value: "super_partner" },
      },
    },
    formRules: {
      required: (value) => !!value || "Обязательное поле.",
      requiredRadio: (value) => value !== null || "Обязательное поле.",
      requiredList: (value) =>
        (value && value.length > 0) || "Обязательное поле.",
      counter6: (value) =>
        (value && value.length >= 6) || "Минимум 6 символов.",
      counter8: (value) =>
        (value && value.length >= 8) || "Минимум 8 символов.",
      bin: (value) =>
        (value && value.length === 12) || "Должен быть 12 символов.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Неккоректный формат электронной почты.";
      },
      number: (value) => {
        const pattern = /^(?=.+)(?:[1-9]\d*|0)?(?:\.\d+)?$/;
        return pattern.test(value) || "Некорректное число";
      },
      integer: (value) => {
        const pattern = /^\d+$/;
        return pattern.test(value) || "Некорректное число";
      },
    },
  }),
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    userRole() {
      return this.$store.getters["user/userRole"];
    },
  },
  methods: {
    logout() {
      this.$api
        .createLog({
          message: `Пользователь ${this.user.first_name} ${this.user.last_name} вышел в системы`,
        })
        .then(() => {
          this.$store.dispatch("user/logoutUser", {}, { root: true });
          this.$router.push("/login");
        });
    },
    downloadBarcode(item) {
      if (item.role === "partner") {
        this.$store.dispatch("loading/show");
        this.$api
          .getQRCode({
            params: {
              user_id: item.id,
            },
          })
          .then((response) => {
            const link = document.createElement("a");
            link.href = response.data;
            link.target = "_blank";
            link.setAttribute("download", "qr-code.jpeg");
            document.body.appendChild(link);
            link.click();
            //window.open();
          })
          .catch((error) => console.log(error))
          .finally(() => this.$store.dispatch("loading/hide"));
      } else {
        this.$store.dispatch(
          "notification/show",
          "Для данной роли не предусмотрена генерация QR кода"
        );
      }
    },
  },
});

export default {
  name: "App",
  components: {
    Loading,
  },
  data: () => ({
    parameters: {
      loading: {
        canCancel: false,
        color: "#e7e7e7",
        loader: "dots",
        width: 128,
        height: 128,
        backgroundColor: "#000",
        opacity: 0.7,
        zIndex: 10000,
      },
      notification: {
        timeout: 7000,
      },
    },
  }),
  mounted() {
    this.$store.dispatch("loading/hide");
  },
  computed: {
    loading() {
      return this.$store.getters["loading/status"];
    },
    notificationText() {
      return this.$store.getters["notification/text"];
    },
    notificationStatus: {
      get() {
        return this.$store.getters["notification/status"];
      },
      set() {
        this.$store.dispatch("notification/hide");
      },
    },
  },
};
</script>
