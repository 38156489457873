import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "@/store/modules/user";
import menu from "@/store/modules/menu";
import loading from "@/store/modules/loading";
import notification from "@/store/modules/notification";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    user: user,
    menu: menu,
    loading: loading,
    notification: notification,
  },
});
